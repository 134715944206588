import { Suspense } from 'react';
import {
  Burger, AppShell, Group, ScrollArea, Box,
} from '@mantine/core';
import { Outlet } from 'react-router-dom';
import { useClickOutside, useDisclosure, useWindowScroll } from '@mantine/hooks';

import Loading from '../content/loading/Loading';
import TopBar from './nav/topbar/Topbar';
import Expand from './nav/navbar/Expand';
import InitialModals from './modals/InitialModals';
import HeaderDisclaimer from './nav/topbar/HeaderDisclaimer';
import Documentation from './nav/navbar/Documentation';

import { mapRoutesToMenu } from './nav/navbar/mapRoutesToMenu';
import { DISPLAY_ENV_WARNING } from '@/constants/env';

import type { LayoutProps } from './types';

import LogoCompleteIcon from '@/assets/logo/logo-complete.svg';

import layoutClasses from './Layout.module.css';
import { useAuth } from '@/hooks/useAuth';
import { QUOINTELLIGENCE_CLIENT_ID } from '@/constants';
import { CLIENT_WARNING, ENV_WARNING } from '@/constants/text/english';

const Layout = ({
  routes,
  topbarItems,
  includeSearch,
  diplayInitialModals,
}: LayoutProps) => {
  const [mobileOpened, { toggle: toggleMobile, close: closeMobile }] = useDisclosure();
  const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure(false);
  const auth = useAuth();
  const [scroll] = useWindowScroll();
  const ref = useClickOutside(closeMobile);
  const isScrolled = scroll.y > 50;
  const hasClientWarning = auth.tokenInfo.client_access_id === QUOINTELLIGENCE_CLIENT_ID;
  const margin = [hasClientWarning, DISPLAY_ENV_WARNING].filter(Boolean);

  return (
    <>
      <AppShell
        layout="alt"
        header={{ height: margin.length > 0 ? (margin.length * 35) + 60 : 60, offset: true }}
        navbar={{
          width: desktopOpened ? 285 : 70,
          breakpoint: 'sm',
          collapsed: { mobile: !mobileOpened, desktop: !desktopOpened },
        }}
        withBorder={false}
        transitionDuration={300}
        transitionTimingFunction="cubic-bezier(0.44, 0.8, 0.71, 0.98)"
        classNames={{
          root: layoutClasses.root,
          navbar: layoutClasses.navbar,
          header: `${layoutClasses.header} ${isScrolled ? layoutClasses.scrolled : ''}`,
        }}
      >
        <AppShell.Header>
          {DISPLAY_ENV_WARNING && <HeaderDisclaimer text={ENV_WARNING} />}
          {hasClientWarning && <HeaderDisclaimer text={CLIENT_WARNING} />}

          <Group h={60} px="md" justify="space-between">
            <Burger opened={mobileOpened} onClick={toggleMobile} hiddenFrom="sm" size="sm" />
            <TopBar topbarItems={topbarItems} scrolled={isScrolled} includeSearch={includeSearch} />
          </Group>
        </AppShell.Header>
        <AppShell.Navbar p="md" ref={ref} id="sidebar">
          <Group>
            <Burger opened={mobileOpened} onClick={toggleMobile} hiddenFrom="sm" size="sm" />
          </Group>

          <AppShell.Section className={layoutClasses.logoSection}>
            <Box className={layoutClasses.logoWrapper}>
              <LogoCompleteIcon className={layoutClasses.logoComplete} />
            </Box>
          </AppShell.Section>

          <AppShell.Section grow my="md" component={ScrollArea} scrollbars="y">
            {mapRoutesToMenu({ routes, sideBarExpanded: mobileOpened || desktopOpened })}
          </AppShell.Section>

          <AppShell.Section>
            <Documentation desktopOpened={desktopOpened} />
          </AppShell.Section>

          <AppShell.Section visibleFrom="sm">
            <Expand toggleDesktop={toggleDesktop} desktopOpened={desktopOpened} />
          </AppShell.Section>

        </AppShell.Navbar>
        <AppShell.Main>
          <Suspense fallback={(
            <Loading
              text="Loading assets"
              height="calc(100vh - calc(var(--app-shell-header-offset, 0rem) + var(--app-shell-padding)))"
            />
          )}
          >
            <Box pt={15}>
              <Outlet />
            </Box>
          </Suspense>
        </AppShell.Main>
      </AppShell>

      {diplayInitialModals && <InitialModals />}
    </>
  );
};

export default Layout;
