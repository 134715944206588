import { ScrollArea } from '@mantine/core';
import classes from './Topbar.module.css';

const HeaderDisclaimer = ({
  text,
}:{
  text: string
}) => (
  <ScrollArea className={classes.disclaimer} scrollbarSize={4} h={35}>
    {text}
  </ScrollArea>
);

export default HeaderDisclaimer;
